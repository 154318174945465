import React from 'react';
import Content from '../Content';
import { kebabCase } from 'lodash';
import { Link } from 'gatsby';
import IframeResizer from 'iframe-resizer-react';

const ArticleTemplate = ({
  content,
  contentComponent,
  cover,
  meta_title,
  meta_desc,
  tags,
  title,
  pictures,
  videos,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <div>
      <h1 className='title is-size-2 has-text-weight-bold is-bold-light'>
        {title}
      </h1>
      <img src={cover} alt={title} />
      <p className='has-text-justified'>
        <PostContent content={content} />
      </p>
      <div>
        <ul className='taglist'>
          {videos && videos.length
            ? videos.map((video) => (
              <li key={video + `tag`}>
                <IframeResizer
                  src={video}
                  style={{ width: '400px', height: '400px' }}
                />
              </li>
            ))
            : null}
        </ul>
      </div>
      <div style={{ marginTop: `4rem` }}>
        <h4>Tags</h4>
        <ul className='taglist'>
          {tags && tags.length
            ? tags.map((tag) => (
              <li key={tag + `tag`}>
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))
            : null}
        </ul>
      </div>
      <div>
        <ul className='taglist'>
          {pictures && pictures.length
            ? pictures.map((picture) => (
              <li key={picture + `tag`}>
                <img alt='' src={picture} />
              </li>
            ))
            : null}
        </ul>
      </div>
      <hr />
    </div>
  );
};

export default ArticleTemplate;
